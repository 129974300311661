<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='流水号'>
            <a-input placeholder="流水号" v-model="searchForm.flowNo"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='导入批次号'>
            <a-input placeholder="导入批次号" v-model="searchForm.batchNo"/>
          </a-form-item>
        </a-col>
        <a-col
          :span="6"
          style="display:block;"
        >
          <a-form-item label='使用状态'>
            <a-select v-model="searchForm.status">
              <a-select-option v-for="it in statusList" :key="it.value">{{it.title}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col
            :span="6"
            style="display:block;"
        >
          <a-form-item label='付款单位'>
            <a-input placeholder="付款单位" v-model="searchForm.oppositeName"/>
          </a-form-item>
        </a-col>
        <a-col
            :span="6"
            :style="{ display: expand ? 'block' : 'none' }"
        >
          <a-form-item label='车牌号'>
            <a-input placeholder="车牌号" v-model="searchForm.remarks"/>
          </a-form-item>
        </a-col>
        <a-col
            :span="6"
            :style="{ display: expand ? 'block' : 'none' }"
        >
          <a-form-item label='到账时间'>
            <a-range-picker v-model="searchForm.transactionTime" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="($root.$data.privateState.action.includes('/BankFlow/TableList') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))">
        <a-col :span="18">
          <a-tag color="blue">共有: {{pagination.total}}条数据</a-tag>
          <a-tag color="purple" v-if="pagination.total > 10000">数据大于1万条，无法统计数据，请进行查询筛选</a-tag>
          <template v-else>
            <a-tag color="purple">到账金额合计: {{tableOtherData && tableOtherData.received ? tableOtherData.received : '无'}}</a-tag>
            <a-tag color="purple">付款金额合计: {{tableOtherData && tableOtherData.payment ? tableOtherData.payment : '无'}}</a-tag>
            <a-tag color="purple">未付金额合计: {{tableOtherData && tableOtherData.unpaid ? tableOtherData.unpaid : '无'}}</a-tag>
          </template>
        </a-col>
        <a-col :span="6" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick">
            查询
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="expand = !expand">
            {{expand ? '收起' : '展开'}} <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>
    
    
    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom:16px" type="flex" justify="end" :gutter="24">
        <!-- <a-col>
          <a-button icon="plus" type="primary" @click="addClick">新增流水号</a-button>
        </a-col> -->
        <a-col>
          <a-upload
            v-if="($root.$data.privateState.action.includes('/BankFlow/ImportList') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))"
            name="file"
            :file-list="uploadList"
            :action="fileUrl"
            :headers="fileUploadHeaders"
            :multiple="false"
            @change="handleChangeFile"
          >
            <a-button icon="cloud-upload" type="primary">批量导入</a-button>
          </a-upload>
        </a-col>
        <a-col>
          <a-button  icon="cloud-upload" type="danger" @click="deleteBatch" v-if="($root.$data.privateState.action.includes('/BankFlow/DeleteBatch') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))">删除导入批次</a-button>
        </a-col>
        <a-col>
          <a-button icon="cloud-download" style="background-color: #909399;color: #fff;border-color: #909399;" @click="exportExcel" v-if="$root.$data.privateState.action.includes('/BankFlow/ExportExcel') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">导出当前结果</a-button>
        </a-col>
        <a-col>
          <a-button icon="percentage" style="background-color: #909399;color: #fff;border-color: #909399;" @click="setDiscountClick" v-if="$root.$data.privateState.action.includes('/BankFlow/SetDiscount') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)">批量设置贴点比例</a-button>
        </a-col>
      </a-row>
      <a-row style="padding-bottom:16px" type="flex" justify="end">
        <a-col>
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="column-height" />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'default' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'default'">默认</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'middle' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'middle'">中等</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" :class="tableTdStyle === 'small' ? 'ant-table-td-style-selected': ''" @click="tableTdStyle = 'small'">紧凑</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'" @click="() => {screenfull.toggle();isFullscreen=!isFullscreen}"/>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button style="color: rgba(0,0,0,.65);" type="link" icon="reload" @click="searchClick" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group :value="showColumns" @change="changeColumns">
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{it}}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button style="color: rgba(0,0,0,.65);" type="link" icon="setting" />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <!-- table 控制 end -->
      <a-table
        v-if="($root.$data.privateState.action.includes('/BankFlow/TableList') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role))"
        :columns="columns"
        :rowKey="record => record.flowNo"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 2500 }"
        bordered
        :size="tableTdStyle"
      >
        <template #transactionTime="transactionTime">
          {{moment(transactionTime).format('YYYY-MM-DD')}}
        </template>
        <template #createdAt="createdAt">
          {{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
        <template #status="status">
          <a-tag :color="bankFlowStatus[status].color">{{bankFlowStatus[status].text}}</a-tag>
        </template>
        <template #claimIndemnity="claimIndemnity">
          <div v-if="claimIndemnity">
            <p color="orange" v-for="it in Object.keys(claimIndemnity)" :key="it">{{it}}:({{claimIndemnity[it]}})</p>
          </div>
        </template>
        <template #action="data">
          <a-popconfirm
            v-if="($root.$data.privateState.action.includes('/BankFlow/DeleteBatchBySingle') || ['SuperAdmin', 'Admin'].includes($root.$data.privateState.role)) && data.status < 1"
            title="是否确认删除该项?"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete(data)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
          <!-- <a-button v-if="data.status === 1" type="link" @click="editClick(data)">编辑</a-button>
          <a-button v-if="data.status === 1" type="link" @click="uploadSingleImageClick(data.flowNo)">上传图片</a-button> -->
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <a-modal
      v-model="showErrorModal"
      @ok="showErrorModal = false"
      @cancel="showErrorModal = false"
      title="错误批次号"
      width="90%"
    >
      <a-table :rowKey="(record) => record.value" :columns="[{title: '错误', dataIndex: 'type'},{title: '位置', dataIndex: 'value'}]" :data-source="errorList">
      </a-table>
    </a-modal>
    <a-modal
      v-model="showDeleteBatchModal"
      @ok="deleteBatchOk"
      title="删除导入批次"
    >
      <a-input addon-before="批次号" v-model="deleteBatchNo" />
    </a-modal>

    <!-- <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelPreview">
      <div v-viewer>
        <img alt="example" style="width: 100%" :src="previewImage" />
      </div>
    </a-modal> -->
    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>
    <!-- 设置贴点比例 -->
    <a-modal
      v-model="showSetDiscount"
      title="设置贴点比例"
      @ok="setDiscountOk"
      @cancel="showSetDiscount = false"

    >
    <a-form-model :model="setDiscountForm" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="转款时间">
        <a-range-picker style="width:100%" :disabled-date="c => c > moment().endOf('day')" v-model="setDiscountForm.transactionTime" />
      </a-form-model-item>
      <a-form-model-item label="付款公司">
        <a-input v-model="setDiscountForm.inName" />
      </a-form-model-item>
      <a-form-model-item label="收款公司">
        <a-input v-model="setDiscountForm.outName" />
      </a-form-model-item>
      <a-form-model-item label="贴点比例">
        <a-input-number
          :default-value="100"
          :min="0"
          :max="100"
          :formatter="value => `${value}%`"
          :parser="value => value.replace('%', '')"
          v-model="setDiscountForm.subsidyRatio"
        />
      </a-form-model-item>
    </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import licia from 'licia'
import moment from 'moment'
import screenfull from 'screenfull'
import { BankFlow } from '@/api'
import { aliveVM, mixinTableList } from '@/utils/mixin'
import DownloadJS from "downloadjs";
import Store from '@/store'


const INIT_SEARCH_FORM = '{"flowNo":"","batchNo":"","status":"","oppositeName":"","remarks":"","transactionTime":[]}'
const columns = [
  {
    title: '到账时间',
    width: 150,
    dataIndex: 'transactionTime',
    scopedSlots: { customRender: 'transactionTime' },
  },
  {
    title: '备注',
    width: 150,
    dataIndex: 'remarks',
  },
  {
    title: '对方户名',
    width: 150,
    dataIndex: 'oppositeName',
  },
  {
    title: '到账金额',
    width: 150,
    dataIndex: 'credits',
  },
  {
    title: '流水号',
    width: 150,
    dataIndex: 'flowNo',
  },
  {
    title: '状态',
    width: 150,
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '关联信息',
    dataIndex: 'claimIndemnity',
    scopedSlots: { customRender: 'claimIndemnity' },
  },
  {
    title: '导入批次号',
    width: 150,
    dataIndex: 'batchNo',
  },
  {
    title: '账号',
    width: 150,
    dataIndex: 'account',
  },
  {
    title: '账号名称',
    width: 150,
    dataIndex: 'accountName',
  },
  {
    title: '对方账号',
    width: 150,
    dataIndex: 'oppositeAccount',
  },
  {
    title: '对方开户行',
    width: 150,
    dataIndex: 'oppositeBankName',
  },
  {
    title: '导入时间',
    dataIndex: 'createdAt',
    width: 150,
    scopedSlots: { customRender: 'createdAt' },
  },
  {
    title: '操作',
    key: 'operation',
    width: 130,
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  }
]
export default {
  name: 'BankFlow',
  mixins: [aliveVM, mixinTableList],
  async created () {
    this.fileUrl = `${this.$appBaseUrl}${BankFlow}/ImportList`
    this.imageFileUrl = `${this.$appBaseUrl}${BankFlow}/UploadImages`
    
    this.fileUploadHeaders = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    const baseColumns = []
    if (Store.privateState.action.includes('/AssessDamageOrder/SettlementExcel') || Store.privateState.action.includes('/AssessDamageOrder/FinancialConfirm') || ['SuperAdmin', 'Admin'].includes(Store.privateState.role))
    {
      this.columns = [{
        title: '贴点比例%',
        width: 100,
        dataIndex: 'subsidyRatio',
      }, ...columns]
      baseColumns.push("贴点比例%")
    }
    for (const it of columns) {
      baseColumns.push(it.title)
    }
    this.$set(this, 'baseColumns', baseColumns)
    // 
    this.fetch()
  },
  computed: {
    showColumns() {
      let c = []
      for (const it of this.columns) {
        c.push(it.title)
      }
      return c
    }
  },
  data () {
    return {
      modalLoading: false,
      modalLoadingMessage: '正在处理数据...',
      // table使用
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${BankFlow}/TableList`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      rowKey: 'uuid',
      // 其他
      moment,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      baseNameList: [],
      sending: false,
      sendingHint: '',
      statusList: [
        { value: '-1', title: '未使用' },
        { value: '1', title: '部分金额使用' },
        { value: '999', title: '已使用' },
        { value: '1100', title: '已完成付款' },
      ],
      bankFlowStatus: {
        '-2': {
          color: 'red',
          text: '删除'
        },
        '-1': {
          color: 'green',
          text: '未使用'
        },
        '1': {
          color: 'purple',
          text: '部分金额使用'
        },
        '999': {
          color: 'orange',
          text: '已使用'
        },
        '1100': {
          color: 'cyan',
          text: '已完成付款'
        },
      },
      // 导入
      uploadList: [],
      fileUrl: '',
      fileUploadHeaders: {},
      // 添加
      showErrorModal: false,
      errorList: [],
      // 删除导入批次
      showDeleteBatchModal: false,
      deleteBatchNo: '',
      // 回执单图片
      showUploadImageModal: false,
      imageFiles: [],
      imageFileUrl: '',
      // 单个回执单图片
      showUploadImageModalBySingle: false,
      imageFilesBySingle: [],
      imageFileUrlBySingle: '',
      imageFilesBySingleFlowNo: '',
      actionMode: 'create',
      // 新增
      previewVisible: false,
      previewImage: '',
      imageFilesByAdd: [],
      addFormModalShow: false,
      addForm: {
        flowNo: '',
        arrivalDate: null,
        amount: 0,
        payer: '',
        reportNo: '',
        carOwner: '',
        plateNo: '',
        remarks: ''
      },
      addFormRules: {
        flowNo: [{ required: true, message: '请输入', trigger: 'blur' }],
        payer: [{ required: true, message: '请输入', trigger: 'blur' }],
        arrivalDate: [{ required: true, message: '请输入', trigger: 'change' }],
        amount: [{ required: true, message: '请输入', trigger: 'blur' }],
        property: [{ required: true, message: '请输入', trigger: 'blur' }],
        // reportNo: [{ required: true, message: '请输入', trigger: 'blur' }],
        // carOwner: [{ required: true, message: '请输入', trigger: 'blur' }],
        plateNo: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      // 编辑
      editFlowNo: '',
      // 设置贴点比例
      showSetDiscount: false,
      setDiscountForm: {
        transactionTime: null,
        subsidyRatio: 0,
        inName: '',
        outName: ''
      }
    }
  },
  methods: {
    changeColumns (v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it)
        }
      }
      this.$set(this, 'columns', c)
    },
    async reload() {
      this.$set(this, 'searchParams', null)
      this.searchForm.resetFields()
      await this.fetch()
    },
    async cleanSearchClick () {
      this.$set(this, 'searchParams', null)
      this.$set(this, 'searchForm', JSON.parse(INIT_SEARCH_FORM))
      await this.fetch()
    },
    async searchClick () {
      this.pagination.current = 1
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        let it = searchForm[k]
        if (k === 'transactionTime') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (k === 'status') {
          it = parseInt(it)
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.$set(this, 'searchParams', searchParams)
      this.fetch()
    },
    // 其他
    async showPreview(data) {
      this.previewImage = `${this.$appBaseUrl}/bank-flow-images/${data.flowNo}.png?t=${new Date().getTime()}`;
      this.previewVisible = true;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day');
    },
    handleChangeFile (info) {
      this.uploadList = [info.fileList[info.fileList.length - 1]]
      if (info.file.status === 'error') {
        this.$message.error('上传文件失败，请尝试重新登录');
        return
      } else if (info.file.status === 'done') {
        this.errorList = []
        if (!info.file.response.status) {
          this.$message.error(info.file.response.message);
          if (info.file.response.data) {
            this.errorList = info.file.response.data
            this.showErrorModal = true
          }
          return;
        }
        if (info.file.response.data && (typeof info.file.response.data) === 'object' && info.file.response.data.length > 0) {
          this.errorList = info.file.response.data
          this.showErrorModal = true
        } else {
          this.$message.success('导入数据成功')
        }
        this.fetch();
      }
    },
    async handleDelete (data) {
      const res = await this.$axios.delete(`${BankFlow}/DeleteBatchBySingle?flowNo=${data.flowNo}`)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        if (!res.status) {
          this.$message.error(res.message)
            return
        }
        this.addFormModalShow = false
        this.$message.success('操作成功')
        this.fetch()
    },
    deleteBatch() {
      this.deleteBatchNo = ''
      this.showDeleteBatchModal = true
    },
    async deleteBatchOk() {
      if (!this.deleteBatchNo) {
        this.$message.error("请填写批次号")
        return
      }
      this.modalLoading = true
      const apiRes = await this.$axios.delete(`${BankFlow}/DeleteBatch?batch=${this.deleteBatchNo}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      this.modalLoading = false
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.$message.success("删除批次数据成功！")
      this.showDeleteBatchModal = false
      this.fetch();
    },
    // 新增
    addClick () {
      this.addFormModalShow = true
      this.actionMode = 'create'
      this.$set(this, 'addForm', {
        flowNo: '',
        arrivalDate: null,
        amount: 0,
        payer: '',
        reportNo: '',
        carOwner: '',
        plateNo: '',
        remarks: ''
      })
      this.$set(this, 'imageFilesByAdd', [])
    },
    editClick (data) {
      this.actionMode = 'edit'
      this.editFlowNo = data.flowNo
      const addForm = {
        flowNo: data.flowNo,
        arrivalDate: data.arrivalDate,
        amount: data.amount,
        payer: data.payer,
        reportNo: data.reportNo,
        carOwner: data.carOwner,
        plateNo: data.plateNo,
        remarks: data.remarks ? data.remarks : ''
      }
      this.$set(this, 'addForm', addForm)
      this.addFormModalShow = true
    },
    handleAddOrEditOk () {
      this.$refs.addForm.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容');
          return
        }
        const postData = JSON.parse(JSON.stringify(this.addForm))
        postData.arrivalDate = moment(this.addForm.arrivalDate).format('YYYY-MM-DD')
        let res = {
          status: false,
          message: '操作类型异常'
        }
        if (this.actionMode === 'create') {
          res = await this.$axios.post(`${BankFlow}/Create`, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        } else if (this.actionMode === 'edit') {
          res = await this.$axios.post(`${BankFlow}/Edit?flowNo=${this.editFlowNo}`, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        }
        if (!res.status) {
          this.$message.error(res.message)
          return
        }
        try {
          if (this.actionMode === 'create') {
            await this.UploadImagesByAdd(this.addForm.flowNo)
          }
        } catch (e) {
          this.$message.error(e.message)
          return
        }
        this.addFormModalShow = false
        this.$message.success('操作成功')
        this.fetch()
      })
    },
    async exportExcel() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      const searchParams = {}
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k]
        if (k === 'transactionTime') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it
          continue
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it
        }
      }
      this.sending = true
      this.sendingHint = '正在获取数据'
      const apiRes = await this.$axios.post(`${BankFlow}/ExportExcel`, {
        results: 20000,
        page: 1,
        searchParams,
      }).catch(e => {
        return {
          status: false,
          message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
        }
      })
      this.sending = false
      if (!apiRes.status) {
        this.sending = false
        this.$message.error(apiRes.message)
        return
      }
      DownloadJS(window.atob(apiRes.data), '回执单列表数据.xlsx')
    },
    setDiscountClick () {
      this.$set(this, 'setDiscountForm', {
        transactionTime: null,
        subsidyRatio: 0,
        inName: '',
        outName: ''
      })
      this.showSetDiscount = true
    },
    async setDiscountOk() {
      if (this.setDiscountForm.transactionTime.length != 2) {
        this.$message.error('请选择时间访问')
        return
      }
      var postData = JSON.parse(JSON.stringify(this.setDiscountForm))
      postData.transactionTime[0] = moment(postData.transactionTime[0]).format('YYYY-MM-DD') + " 00:00:00"
      postData.transactionTime[1] = moment(postData.transactionTime[1]).format('YYYY-MM-DD') + " 23:59:59"
      this.modalLoading = true
      const res = await this.$axios.post(`${BankFlow}/SetDiscount`, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
      this.modalLoading = false
      if (!res.status) {
          this.$message.error(res.message)
          return
        }
      this.showSetDiscount = false
      this.$message.success('设置成功')
      this.fetch()
    }
  }
}
</script>

<style>

</style>